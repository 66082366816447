import { Injectable } from "@angular/core";
import { FilterDateType, DateRangeFilter, ReportFilterService } from "app/models/reportFilters";
import { BehaviorSubject } from "rxjs";
import { ExtendedDateRangeFilter } from "./extended-date-range-filter";
import { StorageService } from "../storage/storage.service";
import { StorageElementKey, StorageModuleKey } from "app/models/storageKeys";
import { getDefaultMonths } from "app/shared/components/filters/date-range-filter/date-range-filter-helper";
import { DateRange } from "app/models/dateRange";

export enum PredefinedDateType {
  CURRENT_MONTH, // 1..today
  CURRENT_YEAR, // 1.1..today
  LAST_WEEK, // monday..sunday
  LAST_MONTH, // 1-28/31
  USER_DEFINED,
  ALL
}

export enum DateRangesNames {
  UserDefined = "Filters.CalculatedPeriodType.UserDefined",
  CurrentCalendarMonth = "Filters.CalculatedPeriodType.CurrentCalendarMonth",
  CurrentCalendarYear = "Filters.CalculatedPeriodType.CurrentCalendarYear",
  LastFourWeeks = "Filters.CalculatedPeriodType.LastFourWeeks",
  LastCalendarMonth = "Filters.CalculatedPeriodType.LastCalendarMonth",
  LastSixCalendarMonths = "Filters.CalculatedPeriodType.LastSixCalendarMonths",
  LastTwelveCalendarMonths = "Filters.CalculatedPeriodType.LastTwelveCalendarMonths",
  All = "Filters.CalculatedPeriodType.All"
}

export interface PredefinedDateRange {
  name: string;
  type: PredefinedDateType;
  duration?: number;
}

interface DateSelected {
  year: number;
  month: number;
  day: number;
}

const DEFAULT_MONTHS = 12;
@Injectable()
export class DatePeriodFilterService implements ReportFilterService {
  public fromDateState?: Date;
  public toDateState?: Date;
  public selectedDatePeriod$?: BehaviorSubject<ExtendedDateRangeFilter>;
  private defaultDateRange: Date[] = getDefaultMonths(DEFAULT_MONTHS);

  constructor(
    private storageService: StorageService,
    private storageModuleKey: StorageModuleKey,
  ) {
    this.initialSelectedDatePeriod();
  }

  private saveLocalState(fromDate: Date, toDate: Date) {
    if (fromDate instanceof Date) {
      this.fromDateState = fromDate;
    }
    if (toDate instanceof Date) {
      this.toDateState = toDate;
    }
  }

  private initialSelectedDatePeriod(): void {
    const getLastSelected = this.getDatesLastSelected();
    if (getLastSelected?.from && getLastSelected?.to) {
      this.saveLocalState(getLastSelected.from, getLastSelected.to);
      this.selectedDatePeriod$ = new BehaviorSubject<ExtendedDateRangeFilter>(
        {
          type: FilterDateType.USER_DEFINED,
          range: { from: getLastSelected.from, to: getLastSelected.to },
          default: this.isChanged() ? false : true
        }
      );
    } else {
      const getDefaultRange = this.defaultDateRange;
      this.selectedDatePeriod$ = new BehaviorSubject<ExtendedDateRangeFilter>(
        {
          type: FilterDateType.USER_DEFINED,
          range: { from: getDefaultRange[0], to: getDefaultRange[1] },
          default: true
        }
      );
    }
  }

  private saveDateFromCurrentSelected(data: DateSelected): void {
    this.storageService.saveDataToStorage<DateSelected>({
      storageElementKey: StorageElementKey.datePeriodFrom,
      storageModuleKey: this.storageModuleKey
    }, data);
  }

  private saveDateToCurrentSelected(data: DateSelected): void {
    this.storageService.saveDataToStorage<DateSelected>({
      storageElementKey: StorageElementKey.datePeriodTo,
      storageModuleKey: this.storageModuleKey
    }, data);
  }

  private clearAllDataLastSelected() {
    this.storageService.deleteDataFromStorage({
      storageElementKey: StorageElementKey.datePeriodFrom,
      storageModuleKey: this.storageModuleKey
    });
    this.storageService.deleteDataFromStorage({
      storageElementKey: StorageElementKey.datePeriodTo,
      storageModuleKey: this.storageModuleKey
    });
  }

  private getDatesLastSelected(): DateRange | null {
    try {
      const from: DateSelected | null = this.storageService.getDataFromStorage<DateSelected>({
        storageElementKey: StorageElementKey.datePeriodFrom,
        storageModuleKey: this.storageModuleKey
      });
      const to: DateSelected | null = this.storageService.getDataFromStorage<DateSelected>({
        storageElementKey: StorageElementKey.datePeriodTo,
        storageModuleKey: this.storageModuleKey
      });
      if (from && to) {
        const CONVERT_MIN_TO_MILLISECONDS = 60000;
        const dateFrom = new Date(from.year, from.month, from.day);
        const timeFromUTC = dateFrom.getTime() + Math.abs(dateFrom.getTimezoneOffset() * CONVERT_MIN_TO_MILLISECONDS);
        const dateTo = new Date(to.year, to.month, to.day);
        const timeToUTC = dateTo.getTime() + Math.abs(dateTo.getTimezoneOffset() * CONVERT_MIN_TO_MILLISECONDS);
        const obj = {
          from: new Date(timeFromUTC),
          to: new Date(timeToUTC)
        };
        return obj;
      }
    } catch {
      return null;
    }
    return null;
  }

  public selectDatePeriod(fromDate: Date, toDate: Date): void {
    this.saveLocalState(fromDate, toDate);
    this.saveDateFromCurrentSelected({
      year: fromDate.getFullYear(),
      month: fromDate.getMonth(),
      day: fromDate.getDate()
    });
    this.saveDateToCurrentSelected({
      year: toDate.getFullYear(),
      month: toDate.getMonth(),
      day: toDate.getDate()
    });
    this.selectedDatePeriod$?.next({
      type: FilterDateType.USER_DEFINED,
      range: { from: fromDate, to: toDate },
      default: false
    });
  }

  public reset() {
    this.clearAllDataLastSelected();
    const getDefaultRange = this.defaultDateRange;
    this.saveLocalState(getDefaultRange[0], getDefaultRange[1]);
    this.selectedDatePeriod$?.next({
      type: FilterDateType.USER_DEFINED,
      range: { from: getDefaultRange[0], to: getDefaultRange[1] },
      default: true
    });
  }

  private compareDates(firstDate: Date | undefined, secondDate: Date | undefined) {
    if (firstDate instanceof Date && secondDate instanceof Date) {
      if (firstDate.getFullYear() === secondDate.getFullYear() &&
        firstDate.getMonth() === secondDate.getMonth() &&
        firstDate.getDay() === secondDate.getDay()) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public isChanged(): boolean {
    const getDefaultRange = this.defaultDateRange;
    if (this.compareDates(getDefaultRange[0], this.fromDateState) &&
      this.compareDates(getDefaultRange[1], this.toDateState)) {
      return false;
    } else {
      return true;
    }
  }

  public getSelectedDatePeriod(): DateRangeFilter | undefined {
    return this.selectedDatePeriod$?.value;
  }
}
