import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { LoggerService } from "app/services/logger.service";
import { SplitAction, State, BasicButtonState } from "@eplan/flux";
import { StorageService } from "app/services/storage/storage.service";
import { StorageElementKey } from "app/models/storageKeys";
import { Subscription } from "rxjs";
import { Blade } from "app/models/blade";
import { PBIReportResolverService } from "app/services/pbireportresolver.service";

export interface ISplitBookmark extends SplitAction {
  splitBookmark: string;
  order: number;
}

type BookmarkNamesLastSelectedMap = Map<string, string>;

@Component({
  selector: "app-bookmarks",
  templateUrl: "./bookmarks.component.html",
  styleUrls: ["./bookmarks.component.scss"]
})

export class BookmarksComponent implements OnInit, OnDestroy {
  public bookmarksList: ISplitBookmark[] = [];
  public isDisabled: boolean = true;
  public eState: BasicButtonState = State.DEFAULT;
  private bookmarkNamesSubscription?: Subscription;
  private bookmarkNamesLastSelected: BookmarkNamesLastSelectedMap = new Map<string, string>([]);
  private lastBookmarkName?: string;
  private mBookmarkClass: string = "default";
  private mBookmarkNames: string[] = [];

  @Input() public blade?: Blade;

  @Input() set bookmarkNames(bookmarkNames: string[]) {
    if (bookmarkNames && bookmarkNames.length > 1) {
      this.mBookmarkClass = bookmarkNames[0];
      this.mBookmarkNames = bookmarkNames.slice(1);
    }
  }

  constructor(
    private storageService: StorageService,
    private logger: LoggerService,
    private pbiReportResolverService: PBIReportResolverService) { }

  public ngOnInit(): void {
    this.lastBookmarkName = this.getLastSelectedBookmarkName();

    let defaultBookmarkName = this.lastBookmarkName;
    if (defaultBookmarkName === undefined) {
      defaultBookmarkName = this.mBookmarkNames[0];
    }

    for (let i = 0; i < this.mBookmarkNames.length; i++) {
      const splitItem: ISplitBookmark = {
        splitBookmark: this.mBookmarkNames[i],
        order: i,
        translationId: "Bookmarks." + this.mBookmarkNames[i],
        isDefaultAction: this.mBookmarkNames[i] === defaultBookmarkName
      };
      this.bookmarksList.push(splitItem);
    }

    this.bookmarksList.sort((a, b) =>
      a.splitBookmark === defaultBookmarkName ? -1 :
        b.splitBookmark === defaultBookmarkName ? 1 :
          a.order < b.order ? -1 : 1
    );

    const pbiReportsService = this.pbiReportResolverService.get(this.blade);
    if (pbiReportsService) {
      this.bookmarkNamesSubscription = pbiReportsService.bookmarksSubject$.subscribe(
        bookmarkNames => {
          if (bookmarkNames && bookmarkNames.length > 0) {
            const set = new Set<string>(this.bookmarksList.map(item => item.splitBookmark));
            const intersection = new Set(bookmarkNames.filter(x => set.has(x)));
            if (intersection.size !== set.size) {
              this.logger.Error("Bookmarks mismatch: ", bookmarkNames, set, intersection);
            }

            this.isDisabled = false;
            setTimeout(() => {
              pbiReportsService.applyBookmark(this.lastBookmarkName);
            }, 1);
          }
        });
    }
  }

  public ngOnDestroy(): void {
    if (this.bookmarkNamesSubscription) {
      this.bookmarkNamesSubscription.unsubscribe();
    }
  }

  public onClicked(item: SplitAction) {
    const splitItem = item as ISplitBookmark;
    this.setLastSelectedBookmarkName(splitItem.splitBookmark);
    const pbiReportsService = this.pbiReportResolverService.get(this.blade);
    if (pbiReportsService) {
      pbiReportsService.applyBookmark(splitItem.splitBookmark);
    }
  }

  private getLastSelectedBookmarkName(): string | undefined {
    const bookmarkNamesLastSelectedJSON = this.storageService.getDataFromStorage({ storageElementKey: StorageElementKey.bookmarkNamesLastSelected });
    if (bookmarkNamesLastSelectedJSON) {
      this.bookmarkNamesLastSelected = new Map(Object.entries(JSON.parse(bookmarkNamesLastSelectedJSON as string)));
      if (this.mBookmarkClass) {
        return this.bookmarkNamesLastSelected.get(this.mBookmarkClass);
      }
    }

    return undefined;
  }

  private setLastSelectedBookmarkName(name: string) {
    this.lastBookmarkName = name;
    if (this.mBookmarkClass && this.lastBookmarkName) {
      this.bookmarkNamesLastSelected.set(this.mBookmarkClass, this.lastBookmarkName);
      this.storageService.saveDataToStorage({ storageElementKey: StorageElementKey.bookmarkNamesLastSelected }, JSON.stringify(this.bookmarkNamesLastSelected));
    }
  }
}
