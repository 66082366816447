import { Blade } from "app/models/blade";
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { State, BasicButtonState } from "@eplan/flux";
import { PBIReportResolverService } from "app/services/pbireportresolver.service";
import { ProductGroupFilterBatch, ProductGroupTreeFilterService } from "app/services/filters/product-group-tree-filter.service";
import { Subscription } from "rxjs";
import { VISUAL_TYPE_PG } from "app/services/pbireport.service";
import { CheckedDrillElement } from "app/services/filters/catalog-drill-service";
import { CatalogElement } from "app/models/filters/catalogElement";

@Component({
  selector: "app-product-group-tree-filter",
  templateUrl: "./product-group-tree-filter.component.html",
  styleUrls: ["./product-group-tree-filter.component.scss"]
})
export class ProductGroupTreeFilterComponent implements OnInit, OnDestroy {
  public eState: BasicButtonState = State.DEFAULT;
  public disabled = false;

  private selectedGroupSubscription?: Subscription;
  private filterStateSubscription?: Subscription;
  childrenProp = "customChildrenHandling"; // Fake property to prevent eplan-drilldown to handle "ti-angle-right" automatically

  @Input() public blade?: Blade;

  constructor(
    public productGroupTreeFilterService: ProductGroupTreeFilterService,
    private pbiReportResolverService: PBIReportResolverService,
  ) { }

  public ngOnInit() {
    this.selectedGroupSubscription = this.productGroupTreeFilterService.selectedGroup$.subscribe(selected => {
      this.updateColorScheme(selected);
    });

    const pbiReportsService = this.pbiReportResolverService.get(this.blade);
    if (pbiReportsService) {
      this.filterStateSubscription = pbiReportsService.filterState$.subscribe(
        filterState => {
          if (filterState && filterState.type === VISUAL_TYPE_PG) {
            this.disabled = !filterState.enabled;
          }
        });
    }
  }

  public ngOnDestroy(): void {
    if (this.selectedGroupSubscription) {
      this.selectedGroupSubscription.unsubscribe();
    }
    if (this.filterStateSubscription) {
      this.filterStateSubscription.unsubscribe();
    }
  }

  public updateColorScheme(selected: ProductGroupFilterBatch): void {
    this.eState = selected.filters.length > 0 ? State.PRIMARY : State.DEFAULT;
  }

  public onExpandTreeItem($event: MouseEvent, item: CheckedDrillElement<CatalogElement>): void {
    this.productGroupTreeFilterService.goToDirectory(item);
    $event.preventDefault();
    $event.stopImmediatePropagation()
  }
}
