<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div
  *ngIf="manufacturerFilterService.isSingle$ | async"
  class="manufacturersListLogo lo32 lo32-card {{getManufacturerCssCode(manufacturerFilterService.selectedMan$.getValue())}}"
></div>
<eplan-dropdown *ngIf="manufacturerFilterService.isMulti$ | async">
  <button eplanButton eplanDropdownToggle [eState]="manufacturerFilterService.manEState">
    {{ manufacturerFilterService.selectedMan$.getValue()?.name }}
  </button>
  <div eplanDropdownMenuHeader (click)="$event.stopPropagation()">
    <input eplanInput [(ngModel)]="text" type="search" />
  </div>
  <ng-container
    [ngTemplateOutlet]="manItems"
    [ngTemplateOutletContext]="{manList:manufacturerFilterService.favoriteMans$}"
  ></ng-container>
  <div class="man-divider"></div>
  <ng-container
    [ngTemplateOutlet]="manItems"
    [ngTemplateOutletContext]="{manList:manufacturerFilterService.nonFavoriteMans$}"
  ></ng-container>
</eplan-dropdown>

<ng-template #manItems let-manList='manList'>
  <div
    class="dropdown-item"
    *ngFor="let man of manList.getValue() | mansfilter: text"
  >
    <div
      class="man-star"
      [ngClass]="{
        'fl-icon-star-full': man.favorite,
        'ti-star': !man.favorite
      }"
      (click)="onStarSelected(man); $event.stopPropagation()"
    ></div>
    <div class="man-item" (click)="onManSelected(man)">
      <div class="man-name">
        {{ man.name }}
      </div>
      <div
        class="man-onboarded">
        {{ man.onboarded ? "" : "not onboarded" }}
      </div>
      <div
        class="man-logo lo32 lo32-dropdown {{getManufacturerCssCode(man)}}"
      ></div>
    </div>
  </div>
</ng-template>