import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, ViewChild, TemplateRef, Inject } from "@angular/core";
import { State, BasicButtonState } from "@eplan/flux";
import { Subscription } from "rxjs";
import { ManufacturerFilterService } from "app/services/filters/manufacturer-filter.service";
import { EDSFilterService } from "app/services/filters/eds-filter.service";
import { DatePeriodFilterService } from "app/services/filters/date-period-filter.service";
import { GlobalSearchFilterService } from "app/services/filters/global-search-filter.service";
import { ReportFilters, FilterDateType, ReportFilterService, ReportConfig, Visual } from "app/models/reportFilters";
import { RegionFilterService } from "app/services/filters/region-filter.service";
import { ProductGroupTreeFilterService } from "app/services/filters/product-group-tree-filter.service";
import { ManufacturerCatalogFilterService } from "app/services/filters/manufacturer-catalog-filter.service";
import { Blade } from "app/models/blade";
import { ActiveBladeService } from "app/services/blade/active-blade.service";
import { PbiUserSelectedService, ReportPage, UserSelectedState } from "app/services/filters/pbi-user-selected.service";
import { CS11_APP_DASHBOARD_DATE_PERIOD_FILTER_SERVICE, CS11_APP_REPORTS_DATE_PERIOD_FILTER_SERVICE } from "app/services/injection-tokens";

const FILTER_EMIT_DELAY: number = 200;

@Component({
  selector: "app-filters-panel",
  templateUrl: "./filters-panel.component.html",
  styleUrls: ["./filters-panel.component.scss"]
})
export class FiltersPanelComponent implements OnInit, OnDestroy {
  @Input() public blade?: Blade;
  @Input() public tabBlades: Blade[] = [];
  @Input() public manufacturerEnabled: boolean = false;
  @Input() public productGroupTreeEnabled: boolean = false;
  @Input() public manufacturerCatalogEnabled: boolean = false;
  @Input() public edsEnabled: boolean = false;
  @Input() public datePeriodEnabled: boolean = false;
  @Input() public searchEnabled: boolean = false;
  @Input() public regionEnabled: boolean = false;
  @Input() public exportEnabled: boolean = false;
  @Input() public bookmarksEnabled: boolean = false;
  @Input() public bookmarkNames: string[] = [];
  @Input() public reportCategories: string[] = [];
  @Output() public reportConfigChanged: EventEmitter<ReportConfig> = new EventEmitter();

  public isActivated: boolean = false;
  public manufacturerCatalogAvailable: boolean = false;
  public clearAllColorEState: BasicButtonState = State.DEFAULT;

  public reportConfig: ReportConfig = {
    code: "",
    filters: {
      dateRange: {
        type: FilterDateType.ALL
      },
      genericProductGroups: [],
      productGroups: [],
      eds: {
        enabled: false
      },
      parts: []
    }
  };

  private selectedManufacturerSubscription?: Subscription;
  private selectedProductGroupTreeSubscription?: Subscription;
  private selectedManufacturerCatalogSubscription?: Subscription;
  private selectedDatePeriodSubscription?: Subscription;
  private selectedEDSSubscription?: Subscription;
  private searchSubscription?: Subscription;
  private regionSubscription?: Subscription;
  private subscriptions = new Subscription();
  private services: ReportFilterService[] = [];
  private emitTimeoutId: ReturnType<typeof setTimeout> | null = null;
  public datePeriodFilterService!: DatePeriodFilterService;
  public clearVisibility: boolean = false;

  @ViewChild("manufacturer", { static: true }) private manufacturerRef?: TemplateRef<unknown>;
  @ViewChild("productGroupTree", { static: true }) private productGroupTreeRef?: TemplateRef<unknown>;
  @ViewChild("manufacturerCatalog", { static: true }) private manufacturerCatalogRef?: TemplateRef<unknown>;
  @ViewChild("eds", { static: true }) private edsRef?: TemplateRef<unknown>;
  @ViewChild("datePeriod", { static: true }) private datePeriodRef?: TemplateRef<unknown>;
  @ViewChild("search", { static: true }) private searchRef?: TemplateRef<unknown>;
  @ViewChild("region", { static: true }) private regionRef?: TemplateRef<unknown>;
  @ViewChild("clear", { static: true }) private clearRef?: TemplateRef<unknown>;
  @ViewChild("bookmarks", { static: true }) private bookmarksRef?: TemplateRef<unknown>;

  public filterGroupTemplates: TemplateRef<unknown>[] = [];

  constructor(
    public manufacturerFilterService: ManufacturerFilterService,
    public productGroupTreeFilterService: ProductGroupTreeFilterService,
    public manufacturerCatalogFilterService: ManufacturerCatalogFilterService,
    public edsFilterService: EDSFilterService,
    public globalSearchFilterService: GlobalSearchFilterService,
    public regionFilterService: RegionFilterService,
    public activeBladeService: ActiveBladeService,
    private pbiUserSelectedService: PbiUserSelectedService,
    @Inject(CS11_APP_DASHBOARD_DATE_PERIOD_FILTER_SERVICE) private dashboardDatePeriodFilterService: DatePeriodFilterService,
    @Inject(CS11_APP_REPORTS_DATE_PERIOD_FILTER_SERVICE) private reportsDatePeriodFilterService: DatePeriodFilterService,
  ) { }

  public setActivate(state: boolean) {
    if (state !== this.isActivated) {
      if (state) {
        this.activate();
        this.isActivated = true;
      } else {
        this.isActivated = false;
        this.deactivate();
      }
    }
  }

  public activate() {
    if (this.manufacturerEnabled) {
      this.selectedManufacturerSubscription = this.manufacturerFilterService.selectedMan$.subscribe(selected => {
        this.updateClearVisibility();
        this.updateFilterGroupTemplates();
        if (selected) {
          if (selected.onboarded) {
            this.setReportCode(selected.code);
            this.applyReportFilters({
              manufacturer: {
                code: ""
              }
            });
          } else {
            this.setReportCode("eplnno");
            this.applyReportFilters({
              manufacturer: {
                code: selected.code
              }
            });
          }
        }
      });
      this.services.push(this.manufacturerFilterService);
    }
    if (this.productGroupTreeEnabled) {
      this.selectedProductGroupTreeSubscription = this.productGroupTreeFilterService.selectedGroup$.subscribe(
        selected => {
          this.updateClearVisibility();
          this.updateFilterGroupTemplates();
          const pgFilters = this.productGroupTreeFilterService.getSelectedFilters();
          this.applyReportFilters(selected.allSelected ? {
            genericProductGroups: [],
            productGroups: []
          } : {
            genericProductGroups: pgFilters.filter(item => item.pgID === undefined),
            productGroups: pgFilters.filter(item => item.pgID !== undefined)
          });
        });
      this.services.push(this.productGroupTreeFilterService);
    }
    if (this.manufacturerCatalogEnabled) {
      this.selectedManufacturerCatalogSubscription = this.manufacturerCatalogFilterService.selectedGroup$.subscribe(
        selected => {
          this.manufacturerCatalogAvailable = this.manufacturerCatalogFilterService.isAvailable;
          this.updateClearVisibility();
          this.updateFilterGroupTemplates();
          const filters = this.manufacturerCatalogFilterService.getSelectedFilters();
          this.applyReportFilters(selected.allSelected ? {
            manufacturerCatalogFilters: []
          } : {
            manufacturerCatalogFilters: filters
          }
          );
        });
      this.services.push(this.manufacturerCatalogFilterService);
    }
    if (this.edsEnabled) {
      this.selectedEDSSubscription = this.edsFilterService.selected$.subscribe(
        selected => {
          this.updateClearVisibility();
          this.updateFilterGroupTemplates();
          this.applyReportFilters({
            eds: {
              enabled: selected
            }
          });
        });
      this.services.push(this.edsFilterService);
    }
    if (this.datePeriodEnabled) {
      this.selectedDatePeriodSubscription = this.datePeriodFilterService.selectedDatePeriod$?.subscribe(
        selected => {
          this.updateClearVisibility();
          this.updateFilterGroupTemplates();
          this.applyReportFilters({
            dateRange: selected
          });
        });
      this.services.push(this.datePeriodFilterService);
    }
    if (this.searchEnabled) {
      this.searchSubscription = this.globalSearchFilterService.appliedFilters$.subscribe(
        (filters) => {
          this.updateClearVisibility();
          this.updateFilterGroupTemplates();
          this.applyReportFilters({
            parts: filters
          });
        }
      );
      this.services.push(this.globalSearchFilterService);
    }
    if (this.regionEnabled) {
      this.regionSubscription = this.regionFilterService.selectedRegions$.subscribe(
        (regions) => {
          this.updateClearVisibility();
          this.updateFilterGroupTemplates();
          this.applyReportFilters({
            regions: regions.allSelected ? [] : regions.filters
          });
        }
      );
      this.services.push(this.regionFilterService);
    }

    this.updateClearVisibility();
    this.updateFilterGroupTemplates();
  }

  public deactivate() {
    this.selectedManufacturerSubscription?.unsubscribe();
    this.selectedProductGroupTreeSubscription?.unsubscribe();
    this.selectedManufacturerCatalogSubscription?.unsubscribe();
    this.selectedEDSSubscription?.unsubscribe();
    this.selectedDatePeriodSubscription?.unsubscribe();
    this.searchSubscription?.unsubscribe();
    this.regionSubscription?.unsubscribe();
  }

  public ngOnInit() {
    this.datePeriodFilterService = this.blade === Blade.Home ? this.dashboardDatePeriodFilterService : this.reportsDatePeriodFilterService;

    this.subscriptions.add(
      this.activeBladeService.bladeState$.subscribe(bladeState => {
        if (this.tabBlades.includes(bladeState.blade)
          || (this.tabBlades.length === 0 && bladeState.blade === this.blade)) {

          this.setActivate(bladeState.active);
        }
      })
    );

    this.subscriptions.add(
      this.pbiUserSelectedService.dataSelectedState$.subscribe(() => {
        this.changedStateDataSelectionInReport();
      })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public emitConfigChanged() {
    if (this.emitTimeoutId) {
      clearTimeout(this.emitTimeoutId);
    }
    this.emitTimeoutId = setTimeout(() => {
      this.emitTimeoutId = null;
      this.reportConfigChanged.emit(this.reportConfig);
    }, FILTER_EMIT_DELAY);
  }

  public setReportCode(code: string) {
    if (code && this.reportConfig.code !== code) {
      this.reportConfig.code = code;
      this.emitConfigChanged();
    }
  }

  private defined<Type>(a: Type, b: Type, c: Type): Type {
    return a !== undefined ? a : b !== undefined ? b : c;
  }

  private applyReportFilters(filters: ReportFilters, visuals: Visual = { reset: false }) {
    if (visuals?.reset) {
      this.reportConfig.visuals = visuals;
    }

    if (!this.reportConfig.filters) {
      throw new Error("[applyReportFilters] ReportConfig filters is undefined");
    }
    const compareFilters: ReportFilters = {
      dateRange: this.defined(filters.dateRange, this.reportConfig.filters.dateRange, {
        type: FilterDateType.ALL
      }),
      genericProductGroups: this.defined(filters.genericProductGroups,
        this.reportConfig.filters.genericProductGroups, []),
      productGroups: this.defined(filters.productGroups, this.reportConfig.filters.productGroups, []),
      manufacturerCatalogFilters: this.defined(filters.manufacturerCatalogFilters,
        this.reportConfig.filters.manufacturerCatalogFilters, []),
      eds: this.defined(filters.eds, this.reportConfig.filters.eds, {
        enabled: false
      }),
      parts: this.defined(filters.parts, this.reportConfig.filters.parts, []),
      regions: this.defined(filters.regions, this.reportConfig.filters.regions, []),
      manufacturer: this.defined(filters.manufacturer, this.reportConfig.filters.manufacturer, {
        code: ""
      })
    };
    if (JSON.stringify(this.reportConfig.filters) !== JSON.stringify(compareFilters)) {
      this.reportConfig.filters = compareFilters;
      this.emitConfigChanged();
    } else if (visuals?.reset) {
      this.emitConfigChanged();
    }
  }

  private reset() {
    const ifHomeBlade = Blade.Home === this.blade;
    if (this.productGroupTreeEnabled || ifHomeBlade) {
      this.productGroupTreeFilterService.clearSelection();
    }
    if (this.manufacturerCatalogEnabled || ifHomeBlade) {
      this.manufacturerCatalogFilterService.clearSelection();
    }
    if (this.edsEnabled || ifHomeBlade) {
      this.edsFilterService.reset();
    }
    if (this.searchEnabled || ifHomeBlade) {
      this.globalSearchFilterService.reset();
    }
    if (this.regionEnabled || ifHomeBlade) {
      this.regionFilterService.reset();
    }
  }

  private buildManufacturerCatalogFilter(): ReportFilters {
    let result: ReportFilters = {};
    if (this.manufacturerCatalogEnabled && this.manufacturerCatalogFilterService.isModified()) {
      const filters = this.manufacturerCatalogFilterService.getSelectedFilters();
      result = {
        manufacturerCatalogFilters: filters
      };
    }
    return result;
  }

  /*eslint complexity: [2, 12]*/
  public onClearAll(): void {
    this.reset();
    let productGroupTreeFilter: ReportFilters = {};
    if (this.productGroupTreeEnabled && this.productGroupTreeFilterService.isModified()) {
      const pgFilters = this.productGroupTreeFilterService.getSelectedFilters();
      productGroupTreeFilter = {
        genericProductGroups: pgFilters.filter(item => item.pgID === undefined),
        productGroups: pgFilters.filter(item => item.pgID !== undefined)
      };
    }
    const manufacturerCatalogFilter: ReportFilters = this.buildManufacturerCatalogFilter();
    const edsFilter: ReportFilters = this.edsEnabled ? {
      eds: {
        enabled: this.edsFilterService.edsChecked
      }
    } : {};
    if (this.datePeriodEnabled) {
      this.datePeriodFilterService.reset();
    }
    const periodFilter: ReportFilters = this.datePeriodEnabled ? {
      dateRange: this.datePeriodFilterService.getSelectedDatePeriod()
    } : {};
    const searchFilter: ReportFilters = this.searchEnabled ? {
      parts: this.globalSearchFilterService.getSelectedParts()
    } : {};
    const regionFilter: ReportFilters
      = this.regionEnabled && this.regionFilterService.isModified() ? {
        regions: this.regionFilterService.getSelectedRegions()
      } : {};
    const manufacturerFilter: ReportFilters = {
      manufacturer: {
        code: ""
      }
    };
    if (this.manufacturerEnabled || Blade.Home === this.blade) {
      this.manufacturerFilterService.resetToDefault(false);
    }
    const bladeNormalized: ReportPage = this.bladeNormalized();
    const pbiSelectedThisBlade: UserSelectedState = this.pbiUserSelectedService.getStateUserSelected();
    const reloadReport: Visual = { reset: false };
    if (pbiSelectedThisBlade[bladeNormalized]) {
      this.pbiUserSelectedService.setStateByPageName(bladeNormalized, false);
      reloadReport.reset = true;
    }
    this.updateClearVisibility();
    this.updateFilterGroupTemplates();
    this.applyReportFilters(Object.assign({},
      periodFilter, productGroupTreeFilter, manufacturerCatalogFilter,
      edsFilter, searchFilter, regionFilter, manufacturerFilter), reloadReport);
  }

  private changedStateDataSelectionInReport() {
    if (this.isActivated) {
      const bladeNormalized: ReportPage = this.bladeNormalized();
      const checkIfFilterSelected: boolean = this.services.findIndex(service => service.isChanged()) !== -1;
      if (!checkIfFilterSelected) {
        if (this.pbiUserSelectedService.getStateUserSelected()[bladeNormalized]) {
          this.clearVisibility = true;
        } else {
          this.clearVisibility = false;
        }
      }
    }
  }

  private bladeNormalized(): ReportPage {
    let reportPage: ReportPage;
    if (this.blade === "Home") {
      reportPage = ReportPage.Home;
    } else if (this.blade === "Manufacturer") {
      reportPage = ReportPage.Manufacturer;
    } else if (this.blade === "Parts") {
      reportPage = ReportPage.Parts;
    } else if (this.blade === "Users") {
      reportPage = ReportPage.Users;
    } else { reportPage = ReportPage.Home; }
    return reportPage;
  }

  public updateClearVisibility() {
    if (this.isActivated) {
      const currentFilterSelected = this.services.findIndex(service => service.isChanged()) !== -1;
      const bladeNormalized: ReportPage = this.bladeNormalized();
      const currentReportSelected = this.pbiUserSelectedService.getStateUserSelected()[bladeNormalized];
      if (currentFilterSelected || currentReportSelected) {
        if (!this.clearVisibility) {
          this.clearVisibility = true;
        }
      } else if (this.clearVisibility) {
        this.clearVisibility = false;
      }
    } else if (!this.clearVisibility) {
      this.clearVisibility = this.services.findIndex(service => service.isChanged()) !== -1;
    }
  }

  // eslint-disable-next-line complexity
  private updateFilterGroupTemplates(): void {
    this.filterGroupTemplates = [];

    if (this.manufacturerEnabled && this.manufacturerRef) {
      this.filterGroupTemplates.push(this.manufacturerRef);
    }
    if (this.productGroupTreeEnabled && this.productGroupTreeRef) {
      this.filterGroupTemplates.push(this.productGroupTreeRef);
    }
    if (this.manufacturerCatalogEnabled && this.manufacturerCatalogAvailable && this.manufacturerCatalogRef) {
      this.filterGroupTemplates.push(this.manufacturerCatalogRef);
    }
    if (this.edsEnabled && this.edsRef) {
      this.filterGroupTemplates.push(this.edsRef);
    }
    if (this.datePeriodEnabled && this.datePeriodRef) {
      this.filterGroupTemplates.push(this.datePeriodRef);
    }
    if (this.searchEnabled && this.searchRef) {
      this.filterGroupTemplates.push(this.searchRef);
    }
    if (this.regionEnabled && this.regionRef) {
      this.filterGroupTemplates.push(this.regionRef);
    }
    if (this.clearVisibility && this.clearRef) {
      this.filterGroupTemplates.push(this.clearRef);
    }
    if (this.bookmarksEnabled && this.bookmarksRef) {
      this.filterGroupTemplates.push(this.bookmarksRef);
    }
  }
}
