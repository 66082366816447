// Usage of the service:
// 1. Import the SignalRService;
// 2. Make it injected in constructor
// 3. Use as follows:
// this.signalRService.messages$.subscribe(message => console.log(message));

import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthorizationService } from "./auth/authorization.service";
import { Subject, filter, lastValueFrom } from "rxjs";
import { HostService } from "./host.service";
import * as signalR from "@microsoft/signalr";
import { backOff } from "exponential-backoff";
import { ConfigurationService } from "@eplan/ngx-configuration-service";
import { AlertService, State } from "@eplan/flux";
import { LoggerService } from "app/services/logger.service";
import { BACKEND_API_PATH } from "app/models/api";
import { environment } from "environments/environment";
class SignalRConnectionParams {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  public access_token: string = "";
  public url: string = "";
}

export interface SignalRMessage {
  addressee: string;
  payload: unknown;
}

export interface SignalRReportList {
  kind: string;
  id: string;
  name: string;
  category: string;
  isActive: boolean;
  organizationID: string;
}

const SIGNAL_R_CLIENT_SETTINGS_PATH = BACKEND_API_PATH + "/signalrclientsettings";
const MINIMUM_BACKOFF_TIME = 1000; // [ms]
const MAXIMUM_BACKOFF_TIME = 128000; // [ms]
const MAXIMUM_ATTEMPTS = 9999;

@Injectable()
export class SignalRService implements OnDestroy {
  private defaultTarget: string = "erc";
  private signalRNegotiateEndpoint: string;
  private connection?: signalR.HubConnection;
  private notificationsEnabled: boolean = false;
  messages$ = new Subject<SignalRMessage>();

  constructor(
    private http: HttpClient,
    private authService: AuthorizationService,
    private hostService: HostService,
    private configurationService: ConfigurationService,
    private alertService: AlertService,
    private loggerService: LoggerService) {

    this.signalRNegotiateEndpoint = this.hostService.getReportCenterServiceServer() + SIGNAL_R_CLIENT_SETTINGS_PATH;

    this.configurationService.get("environment").then((value) => {
      if ((value === "dev" && environment.debug.signalRDev) || (value !== "dev" && environment.debug.signalR)) {
        this.notificationsEnabled = true;
      }
    });

    this.authService.isUserAuthorized$
      .pipe(
        filter(isUserAuthorized => isUserAuthorized)
      ).subscribe(() => {
        this.initialize();
      });
  }

  ngOnDestroy(): void {
    this.connection?.off(this.defaultTarget);
  }

  private async initialize(): Promise<void> {
    if (this.connection?.state === signalR.HubConnectionState.Connected) {
      return;
    }

    try {
      const connectionParams = await backOff(async () => await this.getConnectionParams(), {
        delayFirstAttempt: false, startingDelay: MINIMUM_BACKOFF_TIME, jitter: "full",
        maxDelay: MAXIMUM_BACKOFF_TIME, numOfAttempts: MAXIMUM_ATTEMPTS
      });
      if (connectionParams && (connectionParams as SignalRConnectionParams).url) {
        this.connection?.off(this.defaultTarget);
        this.connection = this.buildConnection(connectionParams);
        this.connection.onclose(() => {
          this.reconnect();
        });
        this.connection.on(this.defaultTarget, data => this.messageHandler(data));
        return await this.connect();
      }
    } catch (error) {
      this.loggerService.Error("SignalRService initialize error", error);
    }
  }

  private async connect(): Promise<void> {
    return this.connection?.start()
      .then(() => {
        const message = "SignalR connected";
        this.loggerService.Info(message);

        if (this.notificationsEnabled) {
          this.alertService.showNotification({
            type: State.SUCCESS,
            message,
          });
        }
      })
      .catch(err => {
        const message = "SignalR error: " + err.toString();
        this.loggerService.Error(message);

        if (this.notificationsEnabled) {
          this.alertService.showNotification({
            type: State.WARNING,
            message,
          });
        }
        this.reconnect();
      });
  }

  private reconnect() {
    const timeoutMs = 5000;
    const message = "SignalR reconnecting";
    this.loggerService.Info(message);

    if (this.notificationsEnabled) {
      this.alertService.showNotification({
        type: State.PRIMARY,
        message,
      });

    }
    setTimeout(() => {
      this.initialize();
    }, timeoutMs);
  }

  // returns signalR connection
  private buildConnection(connectionParams: SignalRConnectionParams): signalR.HubConnection {
    return new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Error)
      .withUrl(connectionParams.url, { accessTokenFactory: () => connectionParams.access_token })
      .build();
  }

  // returns promise with connection parameters served by DIT endpoint
  private async getConnectionParams(): Promise<SignalRConnectionParams> {
    return lastValueFrom<SignalRConnectionParams>(this.http.post<SignalRConnectionParams>(this.signalRNegotiateEndpoint, null));
  }

  private messageHandler(...args: unknown[]): void {
    const message = args[0] as SignalRMessage;
    if (message.addressee === "hello") {
      this.loggerService.Info("SignalR hello message received");
      return;
    }

    this.messages$.next(message);
  }

  public isConnected(): boolean {
    return this.connection?.state === signalR.HubConnectionState.Connected;
  }
}
