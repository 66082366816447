import { Component, Input, OnInit } from "@angular/core";
import { Blade } from "app/models/blade";
import { HostService } from "app/services/host.service";
@Component({
  selector: "app-request-platform",
  templateUrl: "./request-platform.component.html",
  styleUrls: ["./request-platform.component.scss"]
})
export class RequestPlatformComponent implements OnInit {
  @Input() public loaded: boolean = false;
  public currentBlade: Blade = Blade.RequestPlatform;
  requestPlatformUrl?: string;

  constructor(public hostService: HostService) { }

  ngOnInit(): void {
    this.requestPlatformUrl = this.hostService.getRequestPlatformServer();
  }
}
