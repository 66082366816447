import { Injectable } from "@angular/core";
import { StorageElementKey, StorageModuleKey } from "app/models/storageKeys";

export interface StorageKey {
  storageElementKey: StorageElementKey,

  /**
   * Optional StorageModule to provide a separate namespace for StorageElement.
   * 
   * e.g. dashboard can have different date period than other reports.
   */
  storageModuleKey?: StorageModuleKey,
}

@Injectable({
  providedIn: "root"
})
export class StorageService {

  private getKey(key: StorageKey) {
    return (key.storageModuleKey ? key.storageModuleKey + "." : StorageModuleKey.global) + key.storageElementKey
  }

  public getDataFromStorage<T>(key: StorageKey): T | null {
    try {
      const itemString = localStorage.getItem(this.getKey(key));
      if (itemString !== null) {
        return JSON.parse(itemString);
      }
      return null;
    } catch {
      return null;
    }
  }

  public saveDataToStorage<T>(key: StorageKey, data: T): void {
    try {
      localStorage.setItem(this.getKey(key), JSON.stringify(data));
    } catch {
      // Unable to save data
    }
  }

  public deleteDataFromStorage(key: StorageKey): void {
    localStorage.removeItem(this.getKey(key));
  }

}
