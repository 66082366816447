<div
  class="
    drop
    h-100
    d-flex
    flex-column
    justify-content-center
    align-items-center
  "
>
  <div class="text-center">
    <h3>
      {{ "Auth.NotAuthorizedTitle" | translate }}
      <br /><small> {{ "Auth.NotAuthorizedInfo" | translate }}</small>
    </h3>
  </div>

  <div class="row">
    <div class="col-12 text-center">
      <h1 class="pt-4 pb-3">
        <span class="ti-lock text-primary"></span>
      </h1>
    </div>
  </div>
</div>
