import { Injectable } from "@angular/core";
import { Blade } from "app/models/blade";
import { BehaviorSubject } from "rxjs";

export interface BladeState {
  blade: Blade;
  active: boolean;
}

@Injectable({
  providedIn: "root"
})
export class ActiveBladeService {

  private activeBlade: Blade = Blade.Home;
  public bladeState$: BehaviorSubject<BladeState> = new BehaviorSubject<BladeState>({
    blade: Blade.Home,
    active: false
  });

  public setTarget(target: Blade) {
    if (target === Blade.Home
      || target === Blade.Manufacturer
      || target === Blade.Parts
      || target === Blade.Users
      || target === Blade.RequestPlatform) {
      this.dispatch({
        blade: this.activeBlade,
        active: false
      });
      this.activeBlade = target;
      this.dispatch({
        blade: this.activeBlade,
        active: true
      });
    }
  }

  public dispatch(bladeState: BladeState) {
    this.bladeState$.next(bladeState);
  }
}
