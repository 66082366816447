import { Inject, Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ReportFilterService } from "app/models/reportFilters";
import {
  buildLevelArray, compareCatalogs, getManufacturerCatalogLevel, isCatalogSublevel,
  ManufacturerCatalogFilter, MANUFACTURER_CATALOG_MAX_COLUMNS
}
  from "app/models/filters/manufacturerCatalogFilter";
import { CatalogDrillService } from "./catalog-drill-service";
import { QueryService } from "../query.service";
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from "../storage/storage.service";
import { ManufacturerFilterService } from "./manufacturer-filter.service";
import { StorageElementKey } from "app/models/storageKeys";

export interface ManufacturerCatalogBatch {
  filters: ManufacturerCatalogFilter[];
  allSelected: boolean;
}

@Injectable({
  providedIn: "root"
})
export class ManufacturerCatalogFilterService extends CatalogDrillService<ManufacturerCatalogFilter>
  implements OnDestroy, ReportFilterService {
  public selectedGroup$: BehaviorSubject<ManufacturerCatalogBatch>
    = new BehaviorSubject<ManufacturerCatalogBatch>({ filters: [], allSelected: false});

  constructor(
    queryService: QueryService,
    @Inject(TranslateService) protected override translateService: TranslateService,
    @Inject(StorageService) protected override storageService: StorageService,
    @Inject(ManufacturerFilterService) protected override manufacturerFilterService: ManufacturerFilterService) {
    super(translateService,
      storageService,
      manufacturerFilterService,
      StorageElementKey.manufacturerCatalog,
      MANUFACTURER_CATALOG_MAX_COLUMNS,
      {
        getCatalog: queryService.getManufacturerCatalog.bind(queryService),
        buildLevelArray,
        compareCatalogs,
        getManufacturerCatalogLevel,
        isCatalogSublevel
      });
  }

  public nextSelectedGroup(selected: ManufacturerCatalogFilter[]) {
    const batch: ManufacturerCatalogBatch = {
      filters: selected,
      allSelected: (this.root.children && this.root.children.every(item => item.checked === true)) as boolean
    };
    this.selectedGroup$?.next(batch);
  }
}
