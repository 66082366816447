import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { ReportFilterService, PartFilter, FilterAction } from "app/models/reportFilters";
import { GlobalSearchEntry } from "app/models/globalSearchEntry";
import { QueryService } from "../query.service";
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from "../storage/storage.service";
import { StorageElementKey } from "app/models/storageKeys";
import { ManufacturerFilterService } from "./manufacturer-filter.service";
import { isArray } from "ngx-bootstrap/chronos";

export interface CheckedGlobalSearchEntry extends GlobalSearchEntry {
  action: FilterAction;
  checked: boolean;
}

const minSearchLength: number = 3;
const entriesSearchLimit: number = 100;

@Injectable({
  providedIn: "root"
})
export class GlobalSearchFilterService implements ReportFilterService, OnDestroy {
  public searchText: string = "";
  public findSubscription?: Subscription;
  public suggestions: CheckedGlobalSearchEntry[] = [];
  public applied: CheckedGlobalSearchEntry[] = [];
  public foundEntries: CheckedGlobalSearchEntry[] = [];
  public foundEntries$: Subject<CheckedGlobalSearchEntry[]> = new Subject<CheckedGlobalSearchEntry[]>();
  public selectedText$: Subject<string> = new Subject<string>();
  public appliedFilters$: BehaviorSubject<CheckedGlobalSearchEntry[]> = new BehaviorSubject<CheckedGlobalSearchEntry[]>([]);

  public isResultListExpanded: boolean = false;
  public isResultListFound: boolean = false;
  private selectAllItem: CheckedGlobalSearchEntry;
  private languageSubscription: Subscription;
  private manFilterSubscription: Subscription;
  private selectedTextSubscription: Subscription;
  private previousManCode?: string | null;
  private readonly searchDebounceTimeMs: number = 300;

  constructor(
    private queryService: QueryService,
    private storageService: StorageService,
    private translateService: TranslateService,
    private manFilterService: ManufacturerFilterService
  ) {
    this.selectAllItem = {
      name: translateService.instant("Filters.SelectAll"),
      type: "",
      action: FilterAction.SELECT_ALL,
      checked: false
    };
    this.initialStateOfSearch();

    this.languageSubscription = this.translateService.onLangChange.subscribe(() => {
      this.selectAllItem.name = this.translateService.instant("Filters.SelectAll");
    });

    this.manFilterSubscription = this.manFilterService.selectedMan$.subscribe(event => {
      const changed = (!this.previousManCode) ||
        (!event && this.previousManCode) ||
        (event && this.previousManCode !== event.code);
      if (changed) {
        this.previousManCode = !event ? null : event.code;
        this.reset();
      }
    });

    this.selectedTextSubscription = this.selectedText$
      .pipe(debounceTime(this.searchDebounceTimeMs), distinctUntilChanged())
      .subscribe(() => {
        this.onSearchTextChanged();
      });
  }

  public ngOnDestroy(): void {
    this.cancelFindSubscription();
    this.languageSubscription.unsubscribe();
    this.manFilterSubscription.unsubscribe();
    this.selectedTextSubscription.unsubscribe();
  }

  private initialStateOfSearch() {
    const storedEntries: GlobalSearchEntry[] | null = this.getAppliedLastSelected();
    if (storedEntries && isArray(storedEntries)) {
      this.isResultListFound = true;
      const selectedEntries: CheckedGlobalSearchEntry[] = [];
      storedEntries.forEach(item => selectedEntries.push({
        name: item.name,
        type: item.type,
        action: FilterAction.SELECT_ITEM,
        checked: true
      }));
      this.updateFoundEntries(selectedEntries);
      this.updateSelectAllValue();
      this.applyFiltering();
    } else {
      this.applied = [];
      this.appliedFilters$.next([]);
    }

  }
  private getAppliedLastSelected(): GlobalSearchEntry[] | null {
    return this.storageService.getDataFromStorage({ storageElementKey: StorageElementKey.globalSearchApplied });
  }

  private updateFoundEntries(foundEntries: CheckedGlobalSearchEntry[]): void {
    this.foundEntries = foundEntries;
    this.foundEntries$.next(foundEntries);
  }

  public clearText() {
    this.searchText = "";
    this.selectedText$.next(this.searchText);
    this.removeUnchecked();
  }

  public reset() {
    this.searchText = "";
    this.storageService.deleteDataFromStorage({ storageElementKey: StorageElementKey.globalSearchApplied });
    this.selectedText$.next(this.searchText);
    this.updateFoundEntries([]);
    this.updateSelectAllValue();
    this.applyFiltering();
  }

  public onSearchTextChanged(): void {
    this.removeUnchecked();
    if (this.searchText.length >= minSearchLength) {
      this.cancelFindSubscription();

      const selectedManMetadata = this.manFilterService.selectedMan$.getValue();
      const manCode = selectedManMetadata !== null ? selectedManMetadata.code : null;
      this.findSubscription = this.queryService.findGlobalSearchEntries(
        this.searchText, entriesSearchLimit, manCode).subscribe(entries => {
          const list: CheckedGlobalSearchEntry[] = [];
          if (entries.length > 0) {
            list.push(this.selectAllItem);
          }
          entries.forEach(entry => {
            const checked: CheckedGlobalSearchEntry = {
              name: entry.name,
              type: entry.type,
              action: FilterAction.SELECT_ITEM,
              checked: false
            };
            list.push(checked);
          });
          this.applied.forEach(entry => {
            const item: CheckedGlobalSearchEntry | undefined = list.find(value => value.name === entry.name);
            if (item !== undefined) {
              item.checked = true;
            } else {
              list.push(entry);
            }
          });
          this.updateFoundEntries(list);
          this.updateSelectAllValue();
          this.applyFiltering();
        });
    }
  }

  public isChanged() {
    return this.getSelectedParts().length > 0;
  }

  public removeUnchecked() {
    const filteredFoundEntries = this.foundEntries.filter(entry => entry.checked);
    this.updateFoundEntries(filteredFoundEntries);
    this.applyFiltering();
    this.updateSelectAllValue();
  }

  public updateSelectAllValue() {
    this.selectAllItem.checked = !this.foundEntries.some(
      item => (item.checked && item.action === FilterAction.SELECT_ITEM))
      ? false : this.foundEntries.every(
        item => (item.checked || item.action === FilterAction.SELECT_ALL));

  }

  private setAll(filters: CheckedGlobalSearchEntry[], value: boolean): boolean {
    let changed: boolean = false;
    for (const filter of filters) {
      if (filter.checked !== value) {
        filter.checked = value;
        changed = true;
      }
    }
    return changed;
  }

  public filterEntries(suggestion: CheckedGlobalSearchEntry) {
    if (suggestion.action === FilterAction.SELECT_ALL) {
      this.setAll(this.foundEntries, suggestion.checked);
    } else {
      this.updateSelectAllValue();
    }
    this.applyFiltering();
  }

  public applyFiltering() {
    const suggestionsList: CheckedGlobalSearchEntry[] = [];
    const appliedList: CheckedGlobalSearchEntry[] = [];
    if (this.foundEntries.length > 0) {
      appliedList.push(this.selectAllItem);
    }
    this.foundEntries.forEach(entry => {
      if (entry.action === FilterAction.SELECT_ITEM) {
        if (entry.checked) {
          appliedList.push(entry);
        } else {
          suggestionsList.push(entry);
        }
      }
    });
    this.suggestions = suggestionsList;
    this.applied = appliedList;
    if (appliedList && appliedList.length > 0) {
      const storedEntries: GlobalSearchEntry[] = appliedList.filter(item => (item.action === FilterAction.SELECT_ITEM))
        .map(item => ({ name: item.name, type: item.type }));
      this.storageService.saveDataToStorage({ storageElementKey: StorageElementKey.globalSearchApplied }, storedEntries);
    } else {
      this.storageService.deleteDataFromStorage({ storageElementKey: StorageElementKey.globalSearchApplied });
    }
    this.appliedFilters$.next(this.getAppliedItemFilters());

    this.isResultListExpanded = this.suggestions.length > 0;
    this.isResultListFound = this.foundEntries.length > 0;
  }

  public onSuggestionClicked(suggestion: CheckedGlobalSearchEntry) {
    setTimeout(() => this.filterEntries(suggestion));
  }

  private cancelFindSubscription() {
    if (this.findSubscription) {
      this.findSubscription.unsubscribe();
    }
  }

  public getSelectedParts(): PartFilter[] {
    return this.getAppliedItemFilters();
  }

  public getAppliedItemFilters(): CheckedGlobalSearchEntry[] {
    return this.applied.filter(item => (item.action === FilterAction.SELECT_ITEM));
  }
}
