import { Blade } from "app/models/blade";
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { State, BasicButtonState } from "@eplan/flux";
import { PBIReportResolverService } from "app/services/pbireportresolver.service";
import { VISUAL_TYPE_CR } from "app/services/pbireport.service";
import { RegionFilterService } from "app/services/filters/region-filter.service";
import { RegionFilter } from "app/models/reportFilters";
import { Subscription } from "rxjs";

@Component({
  selector: "app-region-filter",
  templateUrl: "./region-filter.component.html",
  styleUrls: ["./region-filter.component.scss"]
})
export class RegionFilterComponent implements OnInit, OnDestroy {
  public eState: BasicButtonState = State.DEFAULT;
  public disabled = false;

  private selectedGroupSubscription?: Subscription;
  private filterStateSubscription?: Subscription;

  @Input() public blade?: Blade;

  constructor(public regionFilterService: RegionFilterService,
    private pbiReportResolverService: PBIReportResolverService) {
  }

  public ngOnInit() {
    this.selectedGroupSubscription = this.regionFilterService.selectedRegions$.subscribe(
      selected => {
        this.updateColorScheme(selected.filters);
      });

    const pbiReportsService = this.pbiReportResolverService.get(this.blade);
    if (pbiReportsService) {
      this.filterStateSubscription = pbiReportsService.filterState$.subscribe(
        filterState => {
          if (filterState && filterState.type === VISUAL_TYPE_CR) {
            this.disabled = !filterState.enabled;
          }
        });
    }
  }

  public ngOnDestroy(): void {
    if (this.selectedGroupSubscription) {
      this.selectedGroupSubscription.unsubscribe();
    }
    if (this.filterStateSubscription) {
      this.filterStateSubscription.unsubscribe();
    }
  }

  public updateColorScheme(selected: RegionFilter[]): void {
    this.eState = selected.length > 0 ? State.PRIMARY : State.DEFAULT;
  }
}
