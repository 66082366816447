import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DatePeriodFilterService } from "app/services/filters/date-period-filter.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { Subscription } from "rxjs";
import { getSpecificDate, GetSpecificDateEnum, getLastMonths } from "./date-range-filter-helper";
import { BasicState, State } from "@eplan/flux";
import { Blade } from "app/models/blade";
import { PBIReportResolverService } from "app/services/pbireportresolver.service";
import { CS11_APP_DASHBOARD_DATE_PERIOD_FILTER_SERVICE, CS11_APP_REPORTS_DATE_PERIOD_FILTER_SERVICE } from "app/services/injection-tokens";

export interface DateRange {
  rangeTranslationId: string;
  /**
   * Date[0] is start date and Date[1] is end date.
   */
  dateRange: Date[];
}

enum DateRangeMonths {
  Default = 12,
  Extended = 60,
}

@Component({
  selector: "app-date-range-filter",
  templateUrl: "./date-range-filter.component.html",
  styleUrls: ["./date-range-filter.component.scss"]
})
export class DateRangeFilterComponent implements OnInit, OnDestroy {
  @Input() blade?: Blade;
  disabled: boolean = false;
  readonly: boolean = false;
  dates: Date[] = [];
  dateRanges: DateRange[] = [];
  currentState: BasicState = State.DEFAULT;
  isDisabled: boolean = true;
  datePickerConfig: Partial<BsDatepickerConfig> = {};
  private subscriptions = new Subscription();
  private datePeriodFilterService!: DatePeriodFilterService;

  constructor(
    private translateService: TranslateService,
    private pbiReportResolverService: PBIReportResolverService,
    @Inject(CS11_APP_DASHBOARD_DATE_PERIOD_FILTER_SERVICE) private dashboardDatePeriodFilterService: DatePeriodFilterService,
    @Inject(CS11_APP_REPORTS_DATE_PERIOD_FILTER_SERVICE) private reportsDatePeriodFilterService: DatePeriodFilterService,
  ) {
    const minDate = this.getMinDate(DateRangeMonths.Default);
    const maxDate = getSpecificDate(GetSpecificDateEnum.today);
    this.setBsDatePickerConfig(minDate, maxDate);
  }

  private getMinDate(dateRangeMonths: DateRangeMonths): Date {
    const date = getSpecificDate(GetSpecificDateEnum.today);
    date.setUTCMonth(date.getUTCMonth() - dateRangeMonths);
    date.setUTCDate(1);
    return date;
  }

  ngOnInit(): void {
    this.datePeriodFilterService = this.blade === Blade.Home ? this.dashboardDatePeriodFilterService : this.reportsDatePeriodFilterService;

    const fromNgbDate: Date | undefined = this.datePeriodFilterService.fromDateState;
    const toNgbDate: Date | undefined = this.datePeriodFilterService.toDateState;
    if (fromNgbDate && toNgbDate) {
      this.dates = [fromNgbDate, toNgbDate];
    }

    this.subscriptions.add(
      this.datePeriodFilterService.selectedDatePeriod$?.subscribe(selected => {
        if (selected?.default && selected?.range) {
          const { from, to } = selected.range;
          this.dates = [from, to];
          this.validateDateRange(this.dates);
        }
      })
    );

    const pbiReportsService = this.pbiReportResolverService.get(this.blade);
    if (pbiReportsService) {
      this.subscriptions.add(
        pbiReportsService.reportMetadata$.subscribe(reportMetadata => {
          if (reportMetadata) {
            const minDate = this.getMinDate(reportMetadata?.exportData ? DateRangeMonths.Extended : DateRangeMonths.Default);
            this.datePickerConfig = { ...this.datePickerConfig, minDate };
            this.isDisabled = false;
          }
        })
      );
    }
  }

  private setBsDatePickerConfig(minDate: Date, maxDate: Date) {
    this.dateRanges = [
      {
        dateRange: [getSpecificDate(GetSpecificDateEnum.firstDayOfCurrentMonth), getSpecificDate(GetSpecificDateEnum.today)
        ],
        rangeTranslationId: "Filters.CalculatedPeriodType.CurrentCalendarMonth"
      },
      {
        dateRange: [getSpecificDate(GetSpecificDateEnum.firstDayOfCurrentYear),
        getSpecificDate(GetSpecificDateEnum.today)],
        rangeTranslationId: "Filters.CalculatedPeriodType.CurrentCalendarYear"
      },
      {
        dateRange: [getSpecificDate(GetSpecificDateEnum.lastFourWeeks),
        getSpecificDate(GetSpecificDateEnum.today)],
        rangeTranslationId: "Filters.CalculatedPeriodType.LastFourWeeks"
      },
      {
        dateRange: [...getLastMonths(1)],
        rangeTranslationId: "Filters.CalculatedPeriodType.LastCalendarMonth"
      },
      {
        dateRange: [...getLastMonths(6)],
        rangeTranslationId: "Filters.CalculatedPeriodType.LastSixCalendarMonths"
      }, {
        dateRange: [...getLastMonths(12)],
        rangeTranslationId: "Filters.CalculatedPeriodType.LastTwelveCalendarMonths"
      }
    ];

    this.datePickerConfig = {
      adaptivePosition: true,
      useUtc: false, // Explicitly disabled - it's not supported at this moment.
      isAnimated: true,
      showPreviousMonth: false,
      customRangeButtonLabel: this.translateService.instant("Filters.CalculatedPeriodType.UserDefined"),
      maxDate,
      minDate
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private validateDateRange(dates: Date[] | null): boolean {
    if (!dates || isNaN(dates?.[0]?.getDate()) || isNaN(dates?.[1]?.getDate())) {
      this.currentState = State.DANGER;
      return false;
    }

    const fromDate = dates[0];
    const toDate = dates[1];

    if (fromDate > toDate || this.datePickerConfig.minDate && (fromDate < this.datePickerConfig.minDate)) {
      this.currentState = State.DANGER;
      return false;
    }

    this.currentState = State.DEFAULT;
    return true;
  }

  onValueChange(datesIn: Date[] | null): void {
    if (!datesIn) return;

    // Convert calendar date to midnight UTC
    const toUTCDate = (date: Date): Date => {
      return new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000));
    };

    const datesUTC = [toUTCDate(datesIn[0]), toUTCDate(datesIn[1])];

    if (this.validateDateRange(datesUTC) && datesUTC) {
      this.datePeriodFilterService.selectDatePeriod(datesUTC[0], datesUTC[1]);
    }
  }
}
