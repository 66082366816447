export enum StorageElementKey {
  datePeriodFrom = "filters.date-period.from",
  datePeriodTo = "filters.date-period.to",
  eds = "filters.eds.eds",
  productGroupTree = "filters.product-group-tree.items",
  manufacturerCatalog = "filters.manufacturer-catalog.items",
  favoriteManufacturers = "filters.manufacturer.favorites",
  selectedManufacturers = "filters.manufacturer.selected",
  regions = "filters.region.list",
  dataExportDefaultExtension = "data-export-default-extension",
  globalSearchApplied = "filters.global-search.applied",
  bookmarkNamesLastSelected = "bookmark-names-last-selected"
}

/**
 * Use StorageModule to provide a separate namespace for StorageElement.
 * 
 * e.g. dashboard can have different date period than other reports.
 */
export enum StorageModuleKey {
  global = "",
  dashboard = "dashboard",
}