import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { HostService } from "./host.service";
import { BackendEndpointsErrorHandler } from "app/services/backend-endpoints-error-handler.service";
import { ReportList } from "app/models/reportList";
import { ReportMetadata } from "app/models/reportMetadata";
import { ReportRefreshSchedule } from "app/models/reportRefreshSchedule";
import { ReportRefreshStatus } from "app/models/reportRefreshStatus";
import { ReportDedicatedCapacityStatus } from "app/models/reportDedicatedCapacityStatus";
import { ExportToFileData } from "app/models/exportToFileData";
import { ePLANOrgs } from "../models/eplanReportCode";
import { BACKEND_API_PATH } from "app/models/api";

const REPORTS_API_PATH = BACKEND_API_PATH + "/reports";

@Injectable()
export class ReportsService {
  private reportsUrl: string;

  constructor(
    private httpClient: HttpClient,
    private hostService: HostService,
    private backendEndpointsErrorHandler: BackendEndpointsErrorHandler) {
    this.reportsUrl = this.hostService.getReportCenterServiceServer() + REPORTS_API_PATH;
  }

  public getRefreshSchedules(reportId: string): Observable<ReportRefreshSchedule> {
    const refreshUrl = `${this.reportsUrl}/${reportId}/refreshschedule`;
    return this.httpClient.get<ReportRefreshSchedule>(refreshUrl)
      .pipe(catchError(this.backendEndpointsErrorHandler.skipHandler));
  }

  public getRefreshStatuses(reportId: string, maxCount: number, status?: string): Observable<ReportRefreshStatus[]> {
    const searchParams = new URLSearchParams();
    if (maxCount > 0) {
      searchParams.append("maxCount", `${maxCount}`);
    }
    if (status) {
      searchParams.append("status", status);
    }
    const searchParamsString = searchParams.toString().length ? `?${searchParams.toString()}` : "";
    const refreshUrl = `${this.reportsUrl}/${reportId}/refreshes${searchParamsString}`;

    return this.httpClient.get<ReportRefreshStatus[]>(refreshUrl)
      .pipe(catchError(this.backendEndpointsErrorHandler.skipHandler));
  }

  public getAvailableReports(
    category?: string,
    enableCategoryPreferredLanguage?: boolean,
    showhidden?: boolean,
    maufacturer?: string
  ): Observable<ReportList> {
    const params = new URLSearchParams();

    if (category !== undefined) {
      params.append("category", category);

      if (enableCategoryPreferredLanguage !== undefined) {
        params.append("enableCategoryPreferredLanguage", (enableCategoryPreferredLanguage ? "true" : " false"));
      }
    }

    if (showhidden !== undefined) {
      params.append("showHidden", (showhidden ? "true" : " false"));
    }

    if (maufacturer !== undefined) {
      params.append("manufacturer", maufacturer);
    }

    const paramsStr = params.toString();
    const reportsUrl = this.reportsUrl + (paramsStr.length > 0 ? ("?" + paramsStr) : "");

    return this.httpClient.get<ReportList>(reportsUrl)
      .pipe(catchError(this.backendEndpointsErrorHandler.notificationHandler.bind(this.backendEndpointsErrorHandler)));
  }

  public getReportMetadata(reportId: string): Observable<ReportMetadata> {
    const reportURL = this.reportsUrl + "/" + reportId;
    return this.httpClient.get<ReportMetadata>(reportURL)
      .pipe(catchError(this.backendEndpointsErrorHandler.notificationHandler.bind(this.backendEndpointsErrorHandler)));
  }

  private replaceCharacterAt(str: string, index: number, replacement: string): string {
    if (replacement.length !== 1) {
      return str;
    }

    return str.substring(0, index) + replacement + str.substring(index + 1);
  }

  public codeToCSS(code: string): string {
    let result = code;

    if (ePLANOrgs.includes(code)) {
      result = code.toUpperCase();
    } else {
      for (let i = 0; i < result.length; i++) {
        switch (result[i]) {
          case "_": {
            result = this.replaceCharacterAt(result, i, "u");
            break;
          }
          case "+": {
            result = this.replaceCharacterAt(result, i, "p");
            break;
          }
          default:
        }
      }
    }

    return result;
  }

  public getReportDedicatedCapacityStatus(reportId: string): Observable<ReportDedicatedCapacityStatus> {
    const reportURL = `${this.reportsUrl}/${reportId}/dedicated-capacity-status`;
    return this.httpClient.get<ReportDedicatedCapacityStatus>(reportURL)
      .pipe(catchError(this.backendEndpointsErrorHandler.notificationHandler.bind(this.backendEndpointsErrorHandler)));
  }

  public postExportToFileData(reportId: string, fileFormat: string, parameters: Map<string, string>): Observable<ExportToFileData> {
    const exportURL = `${this.reportsUrl}/${reportId}/export-to-file/${fileFormat}`;
    let body = new HttpParams();
    parameters.forEach((value: string, key: string) => {
      body = body.set(key, encodeURIComponent(value));
    });

    return this.httpClient.post<ExportToFileData>(exportURL, body,
      { headers: { "Content-Type": "text/plain" } })
      .pipe(catchError(this.backendEndpointsErrorHandler.fileExportNotificationHandler.bind(
        this.backendEndpointsErrorHandler)));
  }

  public getExportedFile(reportId: string, exportID: string) {
    const fileURL = `${this.reportsUrl}/${reportId}/exported-file/${exportID}`;
    return this.httpClient.get(fileURL, { responseType: "blob" })
      .pipe(catchError(this.backendEndpointsErrorHandler.fileExportNotificationHandler.bind(this.backendEndpointsErrorHandler)));
  }

  public getExportStatus(reportId: string, exportID: string) {
    const fileURL = `${this.reportsUrl}/${reportId}/export-to-file/${exportID}/status`;
    return this.httpClient.get<ExportToFileData>(fileURL)
      .pipe(catchError(this.backendEndpointsErrorHandler.fileExportNotificationHandler.bind(this.backendEndpointsErrorHandler)));
  }

  public postReportActivity(reportId: string): Observable<void> {
    const fileURL = `${this.reportsUrl}/${reportId}/activity`;
    const body = new HttpParams();
    return this.httpClient.post<void>(fileURL, body).
      pipe(catchError(this.backendEndpointsErrorHandler.notificationHandler.bind(this.backendEndpointsErrorHandler)));
  }
}
