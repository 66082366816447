import { Inject, Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ProductGroupFilter, ReportFilterService } from "app/models/reportFilters";
import { QueryService } from "../query.service";
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from "../storage/storage.service";
import { StorageElementKey } from "app/models/storageKeys";
import { CatalogDrillService } from "./catalog-drill-service";
import { Levels } from "app/models/filters/catalogElement";

const EPLAN_CATALOG_MAX_COLUMNS: number = 2;
export interface ProductGroupFilterBatch {
  filters: ProductGroupFilter[];
  allSelected: boolean;
}
// eslint-disable-next-line
function getManufacturerCatalogLevel(entry: ProductGroupFilter): number {
  let result: number = 0;
  if (entry.ptgID !== undefined) {
    result = Levels.L0;
    if (entry.pgID !== undefined) {
      result = Levels.L1;
    }
  }

  return result;
}
// eslint-disable-next-line
export function compareCatalogs(level: number, a1: ProductGroupFilter, a2: ProductGroupFilter): boolean {
  let result: boolean = true;
  if (level >= Levels.L0) {
    result = result && a1.ptgID === a2.ptgID;
    if (level >= Levels.L1) {
      result = result && a1.pgID === a2.pgID;
    }
  }

  return result;
}
// eslint-disable-next-line
export function buildLevelArray(entry: ProductGroupFilter): string[] {
  const result: string[] = [];
  if (entry.ptgID !== undefined) {
    result.push(entry.ptgID);
    if (entry.pgID !== undefined) {
      result.push(entry.pgID);
    }
  }

  return result;
}
// eslint-disable-next-line
export function isCatalogSublevel(a1: ProductGroupFilter, a2: ProductGroupFilter): boolean {
  return a1.ptgID === a2.ptgID && a1.pgID === undefined && a2.pgID !== undefined;
}

@Injectable({
  providedIn: "root"
})
export class ProductGroupTreeFilterService
  extends CatalogDrillService<ProductGroupFilter> implements OnDestroy, ReportFilterService {

  public selectedGroup$: BehaviorSubject<ProductGroupFilterBatch> =
    new BehaviorSubject<ProductGroupFilterBatch>({filters: [], allSelected: false});

  constructor(
    queryService: QueryService,
    @Inject(TranslateService) protected override translateService: TranslateService,
    @Inject(StorageService) protected override storageService: StorageService
  ) {
    super(translateService,
      storageService,
      null,
      StorageElementKey.productGroupTree,
      EPLAN_CATALOG_MAX_COLUMNS,
      {
        getCatalog: ((language: string, man: string | null, levels?: string[]) =>
          queryService.getProductGroups(language, levels)).bind(queryService),
        buildLevelArray,
        compareCatalogs,
        getManufacturerCatalogLevel,
        isCatalogSublevel
      }
    );
  }

  public nextSelectedGroup(selected: ProductGroupFilter[]) {
    const batch: ProductGroupFilterBatch = {
      filters: selected,
      allSelected: (this.root.children && this.root.children?.every(item => item.checked === true)) as boolean
    };
    if (this.selectedGroup$) {
      this.selectedGroup$.next(batch);
    } else {
      this.selectedGroup$ = new BehaviorSubject<ProductGroupFilterBatch>(batch);
    }
  }
}
