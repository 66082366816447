import { Injectable } from "@angular/core";
import { AuthService, UserData, AuthorizationData } from "@eplan/auth";
import { TranslateInitService } from "@eplan/translateinit";
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { EplanWindow } from "app/models/external-libs/eplan-window";

@Injectable()
export class AuthorizationService extends AuthService {
  private minRequiredRights = "CanReadData";
  private modifyRights = "CanModifyData";
  private removeRights = "CanRemoveData";
  private isUserAuthorizedInternal$ = new ReplaySubject<boolean>(1);
  private isSignedInInternal = false;
  isUserAuthorized$ = this.isUserAuthorizedInternal$.asObservable();
  userData$: BehaviorSubject<UserData | null> = new BehaviorSubject<UserData | null>(null);
  canUserModify$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  canUserRemove$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private translate: TranslateInitService
  ) {
    super();

    this.signedIn$.subscribe(signedIn => {
      this.isSignedInInternal = signedIn;
      this.setUserAuthorization();
    });
  }

  private getRightToken(right: string): string {
    return (window as EplanWindow).configuration.eplanAuthService.clientId + "." + right;
  }

  private setUserAuthorization(): void {
    if (this.isSignedInInternal) {
      this.isUserAuthorizedInternal$.next(this.isAuthorized(this.getRightToken(this.minRequiredRights)));
    }
  }

  protected override setAuthorization(authorization: AuthorizationData): void {
    super.setAuthorization(authorization);
    this.setUserAuthorization();
    this.canUserModify$.next(this.isAuthorized(this.getRightToken(this.modifyRights)));
    this.canUserRemove$.next(this.isAuthorized(this.getRightToken(this.removeRights)));
  }

  protected override setUser(user: UserData | null): Promise<void> {
    const result = super.setUser(user);
    this.userData$.next(user);
    if (user?.culture) {
      return this.translate.saveAndReinitializeLanguage(user.culture);
    }
    return result;
  }

  protected getPspLink(): Promise<string> {
    return Promise.resolve("");
  }
}
