import { Injectable, Injector } from "@angular/core";
import { ConfigurationService } from "@eplan/ngx-configuration-service";
import { Logger, LoggerCreator } from "@eplan/logger";
import { UrlHelper } from "app/helpers/url-helper";
import loggerConfig from "app/../loggerConfig.json";
import { AuthorizationService } from "./auth/authorization.service";
import { ApplicationInsightsService } from "@eplan/flux/analytics";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

@Injectable()
export class LoggerService {
  public level?: number;
  private logger?: Logger;

  constructor(
    private injector: Injector,
    private configurationService: ConfigurationService,
  ) {
    this.logsInit();
  }

  /**
   * Levels of logs:
   *    0 - no logs
   *    1 - only error
   *    2 - error & info
   *    3 - error, info & debug
   */
  private logsInit() {
    this.configurationService.get("environment").then((value) => {
      if (value !== "prod") {
        const urlLogLevel = UrlHelper.getParam("logslevel");
        this.level = urlLogLevel ? parseInt(urlLogLevel, 10) : loggerConfig.defaultLevel;
        LoggerCreator.init(urlLogLevel ? { defaultLevel: parseInt(urlLogLevel, 10) } : loggerConfig);
        this.logger = LoggerCreator.create("Global");
        this.logger.Info("init");
      }
    });
  }

  private get organizationUserId(): string {
    return this.injector.get(AuthorizationService)?.userData$.value?.organizationUserId ?? "";
  }

  public Debug(...args: unknown[]): void {
    if (this.logger) {
      this.logger.Debug(args);
    }
  }

  public Error(error: unknown, ...args: unknown[]): void {
    if (this.logger) {
      this.logger.Error(error, args);
    } else {
      if (error) {
        const properties = {
          ["organizationUserId"]: this.organizationUserId,
          ["args"]: ""
        };
        if (args) {
          properties.args = args.toString();
        }
        this.injector.get(ApplicationInsightsService)?.appInsights?.trackException(
          {
            exception: error as Error,
            severityLevel: SeverityLevel.Error
          }, properties);
      }
    }
  }

  public Info(...args: unknown[]): void {
    if (this.logger) {
      this.logger.Info(args);
    }
  }
}
