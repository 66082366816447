const FOUR_WEEKS_DAYS = 28;

export enum GetSpecificDateEnum {
  firstDayOfCurrentMonth = "firstDayOfCurrentMonth",
  today = "today",
  firstDayOfCurrentYear = "firstDayOfCurrentYear",
  lastFourWeeks = "lastFourWeeks"
}

export const getLastMonths = (numberOfMonths: number): Date[] => {
  const date = new Date();
  const lastXMonths = new Date(date.setUTCMonth(date.getUTCMonth() - numberOfMonths, 1));
  lastXMonths.setUTCHours(0, 0, 0, 0);
  const currentMonth = new Date(getSpecificDate(GetSpecificDateEnum.today).setUTCMonth(getSpecificDate(GetSpecificDateEnum.today)
    .getMonth()));
  const lastDayOfMonth = new Date(currentMonth.setDate(0));
  return [lastXMonths, lastDayOfMonth];
};

export const getDefaultMonths = (numberOfMonths: number): Date[] => {
  const date = new Date();
  const lastXMonths = new Date(date.setUTCMonth(date.getUTCMonth() - numberOfMonths, 1));
  lastXMonths.setUTCHours(0, 0, 0, 0);
  return [lastXMonths, getSpecificDate(GetSpecificDateEnum.today)];
};

export const getSpecificDate = (dateType: GetSpecificDateEnum): Date => {
  const dateUTC = new Date();
  dateUTC.setUTCHours(0, 0, 0, 0); // Date comparisons are based on whole days, starting from midnight UTC

  if (dateType === "firstDayOfCurrentMonth") {
    const first = new Date(dateUTC.setDate(1));
    return first;
  } else if (dateType === "today") {
    return dateUTC;
  } else if (dateType === "firstDayOfCurrentYear") {
    return new Date(dateUTC.getFullYear(), 0, 1);
  } else if (dateType === "lastFourWeeks") {
    return new Date(dateUTC.setUTCDate(new Date().getUTCDate() - FOUR_WEEKS_DAYS));
  }

  return dateUTC;
};
