import { Injectable } from "@angular/core";
import { AlertService } from "@eplan/flux";
import { TranslateService } from "@ngx-translate/core";
import { AuthorizationService } from "./auth/authorization.service";
import { Notification as FluxNotification, State } from "@eplan/flux";
import { BehaviorSubject } from "rxjs";

const MINUTES = 30;
const SECONDS = 60;
const MILLISECONDS = 1000;
const TIME_IDLE_TO_LOG_OUT = MILLISECONDS * SECONDS * MINUTES; // Time in ms. 1000 * 60 * 30 = 30 min
const SECONDS_RUN_PBI_ACTIVITY = 60;
const TIME_TO_RUN_PBI_ACTIVITY = MILLISECONDS * SECONDS_RUN_PBI_ACTIVITY; // Time in ms. 1000 * 60 = 1 min
const MINUTES_NOTIFY = 25;
const WHEN_NOTIFY_TO_LOG_OUT = MILLISECONDS * SECONDS * MINUTES_NOTIFY; // Time in ms. 1000 * 60 * 25 = 25 min

@Injectable()
export class UserIdleService {
  private timeoutId?: ReturnType<typeof setTimeout>;
  private notifyTimeoutId?: ReturnType<typeof setTimeout>;
  private intervalId?: ReturnType<typeof setInterval>;
  private fluxNotification: FluxNotification| undefined;
  private anAction: boolean = false;
  private userNotified: boolean = false;
  public refreshHandleActivity$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private authorizationService: AuthorizationService,
    private alertService: AlertService,
    private translate: TranslateService
  ) {

  }

  public textToPrint() {
    return this.translate.instant("Alert.RemainingTime") + "\n" +
      this.translate.instant("Alert.ActionTake");
  }

  public showNotification(): void {
    this.fluxNotification = new FluxNotification();
    this.fluxNotification.message = this.textToPrint();
    this.fluxNotification.type = State.WARNING;
    this.fluxNotification.id = this.alertService.showNotification(this.fluxNotification);
    this.userNotified = true;
  }

  private checkTimeOut() {
    this.timeoutId = setTimeout(
      () => {
        this.authorizationService.signout();
      }, TIME_IDLE_TO_LOG_OUT
    );
    this.notifyTimeoutId = setTimeout(
      () => {
        this.showNotification();
      }, WHEN_NOTIFY_TO_LOG_OUT
    );
  }

  private startInterval() {
    this.intervalId = setInterval(
      () => {
        if (this.anAction) {
          this.refreshHandleActivity$.next(true);
          this.refreshHandleActivity$.next(false);
          this.anAction = false;
        }
      }, TIME_TO_RUN_PBI_ACTIVITY
    );
  }

  public init() {
    this.checkTimeOut();
    this.startInterval();
  }

  public reset() {
    if (!this.anAction) {
      this.anAction = true;
    }
    if (this.userNotified) {
      if(this.fluxNotification) {
        this.alertService.deleteNotification(this.fluxNotification);
      }
      this.userNotified = false;
    }
    clearTimeout(this.timeoutId);
    clearTimeout(this.notifyTimeoutId);
    this.checkTimeOut();
  }
}
