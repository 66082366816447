<ng-template #manufacturer>
  <div class="card">
    <app-manufacturer-filter [reportCategories]="reportCategories"></app-manufacturer-filter>
  </div>
</ng-template>
<ng-template #productGroupTree>
  <div class="card">
    <app-product-group-tree-filter [blade]="blade"></app-product-group-tree-filter>
  </div>
</ng-template>
<ng-template #manufacturerCatalog>
  <div class="card">
    <app-manufacturer-catalog-filter></app-manufacturer-catalog-filter>
  </div>
</ng-template>
<ng-template #eds>
  <div class="card-left-checkbox">
    <app-eds-filter></app-eds-filter>
  </div>
</ng-template>
<ng-template #datePeriod>
  <div class="card">
    <app-date-range-filter [blade]="blade"></app-date-range-filter>
  </div>
</ng-template>
<ng-template #search>
  <div class="card">
    <app-global-search-filter></app-global-search-filter>
  </div>
</ng-template>
<ng-template #region>
  <div class="card">
    <app-region-filter [blade]="blade"></app-region-filter>
  </div>
</ng-template>
<ng-template #clear>
  <div class="card">
    <button eplanButton menu [eState]="clearAllColorEState" (click)="onClearAll()">
      {{ "Filters.ClearAll" | translate }}
    </button>
  </div>
</ng-template>
<ng-template #bookmarks>
  <div class="card">
    <app-bookmarks [blade]="blade" [bookmarkNames]="bookmarkNames"></app-bookmarks>
  </div>
</ng-template>

<div class="cards-container" *ngIf="isActivated">
  <div class="flex-shrink-0 flex-grow-1">
    <eplan-group
      [templates]="filterGroupTemplates"
      [hideFromRightSide]="true"
      [waitForAnimationFrame]=true
    ></eplan-group>
  </div>
  <div class="card-right">
    <div *ngIf="exportEnabled">
      <app-data-export [blade]="blade"></app-data-export>
    </div>
  </div>
</div>
