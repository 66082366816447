import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { EplanAnalyticsService } from "app/services/eplananalytics.service";
import { EplanAnalyticsEvents } from "app/models/eplanAnalyticsEvents";
import { EplanAnalyticsUserAppliedFilterEventContext } from "app/models/eplanAnalyticsUserAppliedFilterEvent";
import { ReportConfig } from "app/models/reportFilters";
import { Blade } from "app/models/blade";
import { take } from "rxjs/operators";
import { Subscription } from "rxjs";

const reportCategory = "dashboard";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit, OnDestroy {
  @Input() public loaded: boolean = false;

  public reportConfig: ReportConfig = { visuals: undefined, code: "", category: undefined, filters: undefined };
  public currentBlade: Blade = Blade.Home;

  private eplanAnalyticsSubscription?: Subscription;

  constructor(
    public eplanAnalyticsService: EplanAnalyticsService) { }

  public ngOnInit() {
    this.eplanAnalyticsSubscription = this.eplanAnalyticsService.sendEplanAnalyticsEvent(
      EplanAnalyticsEvents.USER_VISITED_HOME_PAGE_EVENT).pipe(take(1)).subscribe();
  }

  public ngOnDestroy() {
    if (this.eplanAnalyticsSubscription) {
      this.eplanAnalyticsSubscription.unsubscribe();
    }
  }

  public reportConfigChanged(reportConfig: ReportConfig) {
    this.reportConfig = {
      visuals: reportConfig.visuals,
      code: "",
      category: reportCategory,
      filters: reportConfig.filters
    };

    this.eplanAnalyticsService.sendEplanAnalyticsAppliedFilterEvent(
      EplanAnalyticsUserAppliedFilterEventContext.HOME,
      this.reportConfig.filters
    );
  }
}
